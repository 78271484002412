/* src/styles/index.css */
html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .mainContainer {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    min-height: 100vh; /* Minimum height of the viewport */
    justify-content: space-between; /* Space between main content and footer */
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  main {
    flex-grow: 1; /* Allows main content to grow and take available space */
    display: flex;
    justify-content: center; /* Center horizontally */
    flex-direction: column;
    align-items: center; /* Center vertically */
    width: 100%;
  }

.monospacedText {
    /* font-family: 'Courier New', Courier, monospace; */
    font-weight: 300; /* Thinner font - adjust as needed */
    font-size: 13px;
    font-style: italic;
  }
  
  .logo {
    width: 40px;
    padding: 20px;
  }