/* src/components/Footer/Footer.css */

.footer {
    display: flex;
    justify-content: space-between; /* Space out links evenly */
    padding: 10px 20px; /* Adjust as needed */
    font-weight: 300;
    font-size: 13px;
    font-style: italic;
  }
  
  .footer a {
    text-decoration: none;
    color: inherit; /* Inherits color from parent element */
  }
  