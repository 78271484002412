/* src/components/Header/Header.css */

.header {
    display: flex;
    justify-content: center; /* Align items to the start of the container */
    padding: 10px 20px;
    font-weight: 300;
    font-size: 13px;
    font-style: italic;
}

.header a {
    text-decoration: none;
    color: inherit;
    margin-right: 20px; /* Space out links */
}
