/* src/styles/Artists.css */
.layout {
    background: #000;
    color: #fff;
}

.site-layout-content {
    background: #000;
    padding: 24px;
    min-height: 280px;
    color: #fff;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    background: #001529;
}

.header a {
    color: #fff;
    margin-left: 16px;
    text-decoration: none;
    font-size: 16px;
}

.header .logo {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

.ant-card {
    background: #1c1c1c;
    border-color: #333;
}

.ant-card-cover img {
    border-bottom: 1px solid #333;
}

.ant-card-meta-title,
.ant-card-meta-description,
.ant-typography {
    color: #fff !important;
}

.ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: #40a9ff;
    border-color: #40a9ff;
}

.ant-layout-footer {
    background: #000;
    color: #fff;
}
